import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(32.000000, 27.000000)">
        <path 
          d="M25.4645 13.9134C25.294 12.1946 24.5625 10.8594 23.2699 9.90767C21.9773 8.95596 20.223 8.48011 18.0071 8.48011C16.5014 8.48011 15.2301 8.69318 14.1932 9.11932C13.1563 9.53125 12.3608 10.1065 11.8068 10.8452C11.267 11.5838 10.9972 12.4219 10.9972 13.3594C10.9688 14.1406 11.1321 14.8224 11.4872 15.4048C11.8565 15.9872 12.3608 16.4915 13 16.9176C13.6392 17.3295 14.3778 17.6918 15.2159 18.0043C16.054 18.3026 16.9489 18.5582 17.9006 18.7713L21.821 19.7088C23.7244 20.1349 25.4716 20.7031 27.0625 21.4134C28.6534 22.1236 30.0313 22.9972 31.196 24.0341C32.3608 25.071 33.2628 26.2926 33.902 27.6989C34.5554 29.1051 34.8892 30.7173 34.9034 32.5355C34.8892 35.206 34.2074 37.5213 32.858 39.4815C31.5227 41.4276 29.5909 42.9403 27.0625 44.0199C24.5483 45.0852 21.5156 45.6179 17.9645 45.6179C14.4418 45.6179 11.3736 45.0781 8.75994 43.9986C6.16051 42.919 4.12926 41.321 2.66619 39.2045C1.21733 37.0739 0.457386 34.4389 0.386364 31.2997H9.31392C9.41335 32.7628 9.83239 33.9844 10.571 34.9645C11.3239 35.9304 12.3253 36.6619 13.5753 37.1591C14.8395 37.642 16.267 37.8835 17.858 37.8835C19.4205 37.8835 20.777 37.6562 21.9276 37.2017C23.0923 36.7472 23.9943 36.1151 24.6335 35.3054C25.2727 34.4957 25.5923 33.5653 25.5923 32.5142C25.5923 31.5341 25.3011 30.7102 24.7188 30.0426C24.1506 29.375 23.3125 28.8068 22.2045 28.3381C21.1108 27.8693 19.7685 27.4432 18.1776 27.0597L13.4261 25.8665C9.74716 24.9716 6.84233 23.5724 4.71165 21.669C2.58097 19.7656 1.52273 17.2017 1.53693 13.9773C1.52273 11.3352 2.22585 9.02699 3.64631 7.05256C5.08097 5.07812 7.0483 3.53693 9.5483 2.42898C12.0483 1.32102 14.8892 0.767044 18.071 0.767044C21.3097 0.767044 24.1364 1.32102 26.5511 2.42898C28.9801 3.53693 30.8693 5.07812 32.2188 7.05256C33.5682 9.02699 34.2642 11.3139 34.3068 13.9134H25.4645Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
